import { useEffect, useState } from 'react';
import { PoweredByIcon } from '@cadexchanger/react-component-library';

import AppNavbar from '../components/AppNavbar';
// import ImportButton from '../components/ImportButton';
import ImportButtonWithFunctionalityNotAvailableModal from '../components/ImportButtonWithFunctionalityNotAvailableModal';
import AppNavbarMainMenuItem from '../components/AppNavbarMainMenuItem';
import ModelList, { File } from '../components/ModelList';

interface PredefinedModelsProps {
  filesPath: string;
}

const PredefinedModels: React.FC<PredefinedModelsProps> = ({ filesPath }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [files, setFiles] = useState<Array<File>>([]);

  useEffect(() => {
    if (files.length === 0) {
      fetch('static/data/examples.json')
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          setFiles(data[filesPath]);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div className="d-flex flex-column position-relative vh-100">
      <AppNavbar mainMenu={<AppNavbarMainMenuItem />}>
        {/* Replace with the old implement until user defined model functionality has been agreed with the marketing department. */}
        {/* <ImportButton /> */}
        <ImportButtonWithFunctionalityNotAvailableModal />
      </AppNavbar>
      <div className="flex-fill bg-gray-100 h-100">
        <ModelList loading={loading} files={files} filesPath={filesPath} />
      </div>
      <PoweredByIcon className="position-fixed right-0 bottom-0 m-5" />
    </div>
  );
};

export default PredefinedModels;
