// ****************************************************************************
//
// Copyright (C) 2008-2014, Roman Lygin. All rights reserved.
// Copyright (C) 2014-2020, CADEX. All rights reserved.
//
// This file is part of the CAD Exchanger software.
//
// You may use this file under the terms of the BSD license as follows:
//
// Redistribution and use in source and binary forms, with or without
// modification, are permitted provided that the following conditions are met:
// * Redistributions of source code must retain the above copyright notice,
//   this list of conditions and the following disclaimer.
// * Redistributions in binary form must reproduce the above copyright notice,
//   this list of conditions and the following disclaimer in the documentation
//   and/or other materials provided with the distribution.
//
// THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS 'AS IS'
// AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
// IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE
// ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE
// LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
// CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF
// SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
// INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN
// CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE)
// ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE
// POSSIBILITY OF SUCH DAMAGE.
//
// ****************************************************************************

import { lazy, Suspense } from 'react';
import { Redirect, Route } from 'react-router';
import { Loader } from '@cadexchanger/react-component-library';

import Landing from './pages/Landing';
import PredefinedModels from './pages/PredefinedModels';
const SheetMetalViewer = lazy(() => import('./pages/SheetMetalViewer'));
const WallThicknessViewer = lazy(() => import('./pages/WallThicknessViewer'));
const MachiningViewer = lazy(() => import('./pages/MachiningViewer'));

import GlobalErrorModal from './components/GlobalErrorModal';
import GlobalLoadingModal from './components/GlobalLoadingModal';

export default function App(): JSX.Element {
  return (
    <Suspense fallback={<div className="vw-100 vh-100 d-flex justify-content-center align-items-center"><Loader /></div>}>
      <Route exact path="/" component={Landing} />
      <Route exact path="/demo">
        <Redirect to="/demo/sheet_metal" />
      </Route>
      <Route exact path="/demo/sheet_metal" component={() => <PredefinedModels filesPath="sheet_metal"/>} />
      <Route path={['/demo/sheet_metal/examples/:fileId', '/demo/sheet_metal/:fileId']} component={() => <SheetMetalViewer filesPath="sheet_metal"/>} />
      <Route exact path="/demo/wall_thickness" component={() => <PredefinedModels filesPath="wall_thickness"/>} />
      <Route path={['/demo/wall_thickness/examples/:fileId', '/demo/wall_thickness/:fileId']} component={() => <WallThicknessViewer filesPath="wall_thickness"/>} />
      <Route exact path="/demo/machining" component={() => <PredefinedModels filesPath="machining"/>} />
      <Route path={['/demo/machining/examples/:fileId', '/demo/machining/:fileId']} component={() => <MachiningViewer filesPath="machining"/>} />

      <GlobalLoadingModal />
      <GlobalErrorModal />
    </Suspense>
  );
}
