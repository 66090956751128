import { observer } from 'mobx-react-lite';
import { LoadingModal } from '@cadexchanger/react-component-library';

import { useGlobalLoadingIndicator } from '../contexts/LoadingModalContext';

const GlobalLoadingModal = observer((): JSX.Element => {
  const globalLoadingIndicator = useGlobalLoadingIndicator();

  return (
    <LoadingModal
      message={globalLoadingIndicator.message}
      isShown={globalLoadingIndicator.isShow}
      onHide={() => globalLoadingIndicator.hide()}
    />
  );
});

export default GlobalLoadingModal;
