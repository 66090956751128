import React from 'react';
import clsx from 'clsx';

import logo from '../img/mtk_logo.png';


interface NavbarProps {
  className?: string;
  mainMenu?: React.ReactNode;
  children?: React.ReactNode;
}

export default function AppNavbar({
  className,
  mainMenu,
  children
}: NavbarProps): JSX.Element {
  return (
    <nav className={clsx('d-flex align-items-center w-100 px-5 sticky-top bg-white shadow-sm border-bottom', className)}>
      {mainMenu}
      <img className={clsx('d-none d-md-block', mainMenu && 'ml-6')} src={logo} loading="lazy" />
      <div className="ml-auto my-3">
        {children}
      </div>
    </nav>
  );
}
