import React from 'react';
import clsx from 'clsx';
import axios, { AxiosError } from 'axios';
// // https://stackoverflow.com/a/32686261
import unacceptableEmailDomains from 'email-providers/common.json';

import { Form, FormValidationError, RequiredIcon, FormDataType, FormItem } from '@cadexchanger/react-component-library';

const feedbackFormFields: Array<FormItem> = [
  {
    name: 'FULL_NAME',
    required: true,
  },
  {
    name: 'EMAIL',
    required: true,
  },
];

export interface FeedbackFormProps {
  category: string;
  onSubmit(): void;
  className?: string;
  children?: React.ReactNode;
}

export default function FeedbackForm({
  category,
  className,
  onSubmit,
  children,
}: FeedbackFormProps): JSX.Element {

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const onFormSubmit = (formData: FormDataType): Promise<void> => {
    setLoading(true);

    formData.CATEGORY = category;

    return axios
      .post(`${process.env.REACT_APP_API_URL}/manufacturing/notify`, formData)
      .then(() => {
        setLoading(false);
        onSubmit();
      })
      .catch((error: Error | AxiosError) => {
        setLoading(false);
        if (axios.isAxiosError(error)) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response?.status;
            if (status === 403) {
              throw new FormValidationError(error.response?.data || {});
            }
            setErrorMessage('Internal error happened. Please try again.');
            throw new FormValidationError({});
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            setErrorMessage(`${error.message} happened. Please try again.`);
            throw new FormValidationError({});
          }
        }
        console.error('Request error:', error.message);
        setErrorMessage('Internal error happened. Please try again.');
        throw new FormValidationError({});
      });
  };

  return (
    <Form
      className={className}
      fields={feedbackFormFields}
      onSubmit={onFormSubmit}
      unacceptableEmailDomains={unacceptableEmailDomains}
    >
      <div className="d-flex justify-content-between align-items-center mt-6">
        <button
          type="submit"
          disabled={loading}
          className={clsx('btn btn-primary', loading && 'loading')}
        >
          Submit
        </button>
        <div className="font-size-sm"><RequiredIcon /> indicates required</div>
      </div>
      {errorMessage && (
        <div className="invalid-feedback d-block mt-4">{errorMessage}</div>
      )}
      {children}
    </Form>
  );
}
