import { createContext, useContext } from 'react';
import { makeObservable, observable, action } from 'mobx';

export type ErrorModalImageType = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
export type ErrorModalOnButtonClickType = () => void;

export class ErrorModalState {

  @observable image: ErrorModalImageType | undefined = undefined;

  @observable title: string | undefined = undefined;

  @observable message = '';

  @observable buttonText: string | undefined = undefined;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @observable onButtonClick: ErrorModalOnButtonClickType = () => { };

  @observable isShow = false;

  constructor() {
    makeObservable(this);
  }

  @action
  show(
    message: string,
    title: string | undefined = undefined,
    image: ErrorModalImageType | undefined = undefined,
    buttonText: string | undefined = 'Close',
    onButtonClick: ErrorModalOnButtonClickType | undefined = undefined,
  ): void {
    this.message = message;
    this.title = title;
    this.image = image;
    this.buttonText = buttonText;
    this.onButtonClick = onButtonClick || (() => { this.isShow = false; });
    this.isShow = true;
  }

  @action
  hide(): void {
    this.isShow = false;
  }
}

export const ErrorModalContext = createContext<ErrorModalState>(new ErrorModalState());

export function useGlobalErrorModal(): ErrorModalState {
  return useContext(ErrorModalContext);
}
