import clsx from 'clsx';

export interface WhyChooseCADExchangerCardProps {
  imageSrc: string;
  title: string;
  description: string;
  className?: string;
  'data-aos'?: string;
}

export default function WhyChooseCADExchangerCard({
  imageSrc,
  title,
  description,
  className,
  'data-aos': dataAos
}: WhyChooseCADExchangerCardProps): JSX.Element {
  return <div className={clsx('card shadow-light', className)} data-aos={dataAos}>
    <div className="card-body">
      <img src={imageSrc} alt={title} loading="lazy" />
      <h5 className="card-title mt-5">{title}</h5>
      <p className="card-text">{description}</p>
    </div>
  </div>;
}
