import React from 'react';
import WhyChooseCADExchangerCard from './WhyChooseCADExchangerCard';

interface WhyChooseCADExchangerSectionProps extends React.HTMLAttributes<HTMLElement>{
  title: string;
  cards: Array<{imageSrc: string, title: string, description: string}>,
}

export default function WhyChooseCADExchangerSection({
  title,
  cards,
  children,
  ...props
}: WhyChooseCADExchangerSectionProps): JSX.Element {
  return <section {...props}>
    <div className="container">
      <div className="row justify-content-center">
        <h2 className="mb-9 text-center text-wrap">{title}</h2>
      </div>
      <div className="row justify-content-center align-items-stretch">
        {cards.map((card) => (
          <div className="col-12 col-md-6 mb-7" key={card.title}>
            <WhyChooseCADExchangerCard {...card} className="h-100" data-aos="ade-down-top"/>
          </div>
        ))}
      </div>
      {children}
    </div>
  </section>;
}
