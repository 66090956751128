import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { ReactComponent as ArrowBackIcon } from '../img/icons/arrow-back.svg';
import { ReactComponent as MainMenuIcon } from '../img/icons/main-menu.svg';

export interface MenuProps {
  children?: React.ReactNode;
}

export default function AppNavbarMainMenuItem({
  children
}: MenuProps): JSX.Element {
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-start"
      overlay={
        <Popover id="popover-menu" className="border">
          <Popover.Content className="p-0">
            {children}
            <Link to="/" className="dropdown-item p-3 m-0">
              <ArrowBackIcon className="align-middle"/>
              <span className="align-middle ml-2">Back to start page</span>
            </Link>
            <a target="_blank" rel="noreferrer" href="https://cadexchanger.com/contact-us" className="dropdown-item p-3 m-0">
              Contact us
            </a>
          </Popover.Content>
        </Popover>
      }
    >
      <button role="button" className="btn btn-white p-1 ml-n1 border-0" ><MainMenuIcon /></button>
    </OverlayTrigger>
  );
}
