import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { ErrorModal } from '@cadexchanger/react-component-library';

import { ErrorModalContext } from '../contexts/ErrorModalContext';

const GlobalErrorModal = observer((): JSX.Element => {

  const errorModalGlobalState = useContext(ErrorModalContext);

  return (
    <ErrorModal
      image={errorModalGlobalState.image}
      title={errorModalGlobalState.title}
      message={errorModalGlobalState.message}
      isShown={errorModalGlobalState.isShow}
      onHide={() => errorModalGlobalState.hide() }
      buttonText={errorModalGlobalState.buttonText}
      onButtonClick={errorModalGlobalState.onButtonClick}
    />
  );
});

export default GlobalErrorModal;
