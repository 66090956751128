import { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import FunctionalityNotAvailableModal from './FunctionalityNotAvailableModal';

import './ManufacturingProcessCard.scss';

export interface ManufacturingProcessCardProps {
  name: string;
  description: string;
  img: string;
  link?: string;
  className?: string;
}

export default function ManufacturingProcessCard({
  name,
  description,
  img,
  link,
  className,
}: ManufacturingProcessCardProps): JSX.Element {
  const [modalShow, setModalShow] = useState(false);

  const onNotifyMeClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setModalShow(true);
    e.preventDefault();
  };

  return <div className={clsx('manufacturing-process-card card', className)}>
    <div className="card-img-top py-xxl-4">
      <img className="d-block mx-auto mw-100" src={img} alt={name} loading="lazy"/>
    </div>
    <div className="d-flex justify-content-end py-4 px-5 position-absolute left-0 right-0">
      <div className={clsx('btn btn-white px-4 py-1 mr-3 font-size-sm font-weight-bold', link && 'd-none')} style={{ pointerEvents: 'none' }}>Coming soon</div>
    </div>
    <h3 className="card-title mt-5 mb-3">{name}</h3>
    <p className="card-text mb-5">{description}</p>
    {link ? (
      <Link to={link} className="btn btn-primary align-self-start mt-auto">Try online demo</Link>
    ) : (
      <button type="button" className="btn btn-link align-self-start px-0 mt-auto" onClick={onNotifyMeClick}>Notify me</button>
    )}
    <FunctionalityNotAvailableModal
      functionality={name}
      isShown={modalShow}
      onHide={() => setModalShow(false)}
    />
  </div>;
}
