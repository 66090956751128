import { useRef } from 'react';
import { AnchorLink, BrandNavBar, Footer, LinkWithArrow } from '@cadexchanger/react-component-library';

import WhyChooseCADExchangerSection from '../components/WhyChooseCADExchangerSection';
import ManufacturingProcessCard, { ManufacturingProcessCardProps } from '../components/ManufacturingProcessCard';

import Landing from '../img/Landing.webp';
import Landing2x from '../img/Landing2x.webp';
import BulbOnImage from '../img/icons/bulb-on.svg';
import ManufacturingProcessImage from '../img/icons/manufacturing-process.svg';
import CostAnalisysImage from '../img/icons/cost-analisys.svg';
import DFMAAnalysisImage from '../img/icons/DFMA-analysis.svg';

import CustomMaasSolutionImage from '../img/custom-maas-solution.webp';
import WhatUnderTheHoodImage from '../img/what-under-the-hood.webp';

import SMLogo from '../img/sheet_metal.webp';
import CNCLogo from '../img/cnc_machining.webp';
import AMLogo from '../img/additive_manufacturing.webp';

const manufacturingProcesses: ManufacturingProcessCardProps[] = [
  {
    name: 'Sheet Metal',
    description: 'Unfolding algorithm to reverse engineer original metal sheets. Recognize bends\' radii and lengths. Analyze thickness and build 2D projections.',
    img: SMLogo,
    link: 'demo/sheet_metal'
  },
  {
    name: 'CNC machining',
    description: 'Recognize machining features: milled surfaces, holes and countersinks (depths & diameters), chamfers, fillets, turning axes, bores, etc.',
    img: CNCLogo,
    link: 'demo/machining'
  },
  {
    name: 'Additive manufacturing',
    description: 'Enable manufacturability analysis: wall thicknesses, sharp edges.',
    img: AMLogo,
    link: 'demo/wall_thickness'
  }
];

export default function LandingPage(): JSX.Element {
  const overview = useRef<HTMLDivElement>(null);

  const scrollToOverview = () => {
    const overviewRect = overview.current?.getBoundingClientRect();
    if (overviewRect) {
      window.scrollTo({
        top: overviewRect.top + window.pageYOffset, /* Relative to the top of the document. */
        behavior: 'smooth',
      });
    }
  };

  return <>
    <BrandNavBar links={[
      { content: 'Overview', to: '#overview', as: AnchorLink },
      { content: 'Benefits', to: '#benefits', as: AnchorLink },
      { content: 'Advanced', to: '#advanced', as: AnchorLink },
    ]} />
    <section className="py-8 py-md-10 bg-white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-5" data-aos="fade-right">
            <h1 className="text-center text-md-left mb-6">Manufacturing Toolkit</h1>
            <p className="text-center text-md-left mb-2">
              Manufacturing Toolkit is an API for the development of on-demand custom manufacturing solutions,
              from instant quotations to DFM analysis.
              The API extracts data from 3D CAD models to recognize features specific to manufacturing processes
              (sheet metal, CNC machining, additive manufacturing and more).
            </p>
            <div className="d-flex flex-wrap justify-content-between mx-n3 mt-6 text-center text-md-left">
              <button className="btn btn-primary flex-fill mx-3 my-2" onClick={scrollToOverview}>
                Try online demo
              </button>
              <a href="https://cadexchanger.com/contact-us/licensing-inquiry/" className="btn btn-outline-primary flex-fill mx-3 my-2" target="_blank" rel="noreferrer">
                Contact sales
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-7 col-xxl-6 offset-xxl-1 mt-7 mt-md-0 text-center text-lg-right " data-aos="fade-left">
            <div className="mt-6 mt-lg-0">
              <img src={Landing} srcSet={`${Landing}, ${Landing2x} 2x`} className="img-landing" alt="CAD Exchanger" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section ref={overview} id="overview" className="py-8 py-md-10 bg-white">
      <div className="container">
        <div className="row justify-content-center">
          <h2 className="col mb-7 mb-md-9 text-center text-wrap">Multiple manufacturing processes</h2>
        </div>
        <div className="row justify-content-center align-items-stretch mt-n7 mt-md-0">
          {manufacturingProcesses.map((props) => (
            <ManufacturingProcessCard key={props.name} {...props} className="col-12 col-md mt-7 mt-md-0"/>
          ))}
        </div>
      </div>
    </section>

    <WhyChooseCADExchangerSection
      id="benefits"
      className="py-8 py-md-10 bg-light"
      title="Why choose CAD Exchanger Manufacturing Toolkit?"
      cards={[
        {
          imageSrc: BulbOnImage,
          title: 'Ready-to-use technologies',
          description: 'Manufacturing Toolkit is built on top of CAD Exchanger SDK, which helps to reduce the product time-to-market and maintenance efforts. Import data from 30+ CAD formats including STEP, SOLIDWORKS, JT and many more.',
        },
        {
          imageSrc: ManufacturingProcessImage,
          title: 'Multiple manufacturing processes',
          description: 'Manufacturing Toolkit supports additive manufacturing, CNC milling, turning, drilling, sheet metal, injection molding and other processes.',
        },
        {
          imageSrc: CostAnalisysImage,
          title: 'Manufacturing cost analysis',
          description: 'Manufacturing Toolkit recognizes raw materials and workpieces, CNC machining features, and performs sheet metal unfolding to estimate the manufacturing cost.',
        },
        {
          imageSrc: DFMAAnalysisImage,
          title: 'DFMA analysis',
          description: 'Manufacturing Toolkit allows to ensure the manufacturability of a part and advise on possible design improvements based on the analysis of hole diameters and depths, wall thicknesses, bend radii and more.',
        },
      ]}
    >
      <div className="row justify-content-center">
        <button className="btn btn-primary mx-3 my-2 w-90 w-md-35 w-lg-20" onClick={scrollToOverview}>
          Try online demo
        </button>
        <a href="https://cadexchanger.com/contact-us/licensing-inquiry/" className="btn btn-outline-primary mx-3 my-2 w-90 w-md-35 w-lg-20" target="_blank" rel="noreferrer">
          Contact sales
        </a>
      </div>
    </WhyChooseCADExchangerSection>

    <section id="advanced" className="py-8 py-md-10 bg-white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-7 text-center text-lg-left" data-aos="fade-left">
            <img src={CustomMaasSolutionImage} className="mw-100 ml-n4" alt="Need a custom Manufacturing-as-a-Service (MaaS) solution?" loading="lazy" />
          </div>
          <div className="col-12 col-lg-5 mt-6 mt-lg-0" data-aos="fade-right">
            <h2 className="text-center text-md-left mb-6">Need a custom Manufacturing-as-a-Service (MaaS) solution?</h2>
            <p className="text-center text-md-left mb-2">
              We build multiple algorithms - from volume computations to machining feature recognition
              - to build custom apps for instant manufacturing quotations.
            </p>
            <a href="https://cadexchanger.com/contact-us/licensing-inquiry/" className="btn btn-outline-primary mt-6" target="_blank" rel="noreferrer">
              Contact sales
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className="py-8 py-md-10 bg-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-7 order-lg-last text-center text-lg-right" data-aos="fade-left">
            <img src={WhatUnderTheHoodImage} className="mw-100" alt="Want to know what’s under the hood" loading="lazy" />
          </div>
          <div className="col-12 col-lg-5 mt-6 mt-lg-0" data-aos="fade-right">
            <h2 className="text-left mb-5 mr-6 mr-xxl-12">Want to know what’s under the hood?</h2>
            <LinkWithArrow to="https://cadexchanger.com/products/sdk/" className="">
              Learn more
            </LinkWithArrow>
          </div>
        </div>
      </div>
    </section>

    <section className="py-8 py-md-10 bg-white">
      <div className="container">
        <div className="row justify-content-center">
          <button className="btn btn-primary mx-3 my-2 w-90 w-md-35 w-lg-20" onClick={scrollToOverview}>
            Try online demo
          </button>
          <a href="https://cadexchanger.com/contact-us/licensing-inquiry/" className="btn btn-outline-primary mx-3 my-2 w-90 w-md-35 w-lg-20" target="_blank" rel="noreferrer">
            Contact sales
          </a>
        </div>
      </div>
    </section>

    <Footer>
      <a href="https://cadexchanger.com/contact-us" className="mt-5">Contact us</a>
    </Footer>
  </>;
}
