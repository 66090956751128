import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Loader } from '@cadexchanger/react-component-library';

import './ModelList.scss';

export interface File {
  id: string;
  name: string;
}

export interface ModelListProps {
  loading: boolean;
  files: Array<File>;
  filesPath: string;
  className?: string;
}

export default function ModelList({
  loading,
  files,
  filesPath,
  className
}: ModelListProps): JSX.Element {

  if (loading) {
    return (
      <div className={clsx('model-list d-flex align-items-center justify-content-center h-100', className)} >
        <Loader />
      </div>
    );
  }
  return (
    <div className={clsx('model-list d-flex flex-wrap align-items-stretch px-5 pb-7', className)}>
      {files.map((file) => (
        <div key={file.name} className="col-12 col-md-4 col-xxl-3 pt-7">
          <Link to={`/demo/${filesPath}/examples/${file.name}`} className="card h-100 border text-decoration-none p-3" title={file.name}>
            <div className="w-100 bg-gray-200 rounded position-relative">
              <img className="w-100" src={`/static/data/examples/${filesPath}/${file.name}/thumbnail.png`} alt={file.name} />
              <div className="btn btn-light btn-open px-6 py-3 font-size-sm font-weight-bold">OPEN MODEL</div>
            </div>
            <div className="mt-4 mx-1">
              <h3>{file.name}</h3>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
