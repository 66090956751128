import React from 'react';
import FunctionalityNotAvailableModal from './FunctionalityNotAvailableModal';

export default function ImportButtonWithFunctionalityNotAvailableModal(): JSX.Element {

  const [modalIsShow, setModalIsShow] = React.useState(false);

  return (
    <>
      <button className="btn btn-primary font-size-sm font-weight-bold" onClick={() => setModalIsShow(true)}>Import 3D</button>
      <FunctionalityNotAvailableModal
        functionality="Import"
        isShown={modalIsShow}
        onHide={() => setModalIsShow(false)}
      />
    </>
  );
}
