import { createContext, useContext } from 'react';
import { makeObservable, observable, action } from 'mobx';

export class LoadingIndicatorState {
  @observable message = '';

  @observable isShow = false;

  constructor() {
    makeObservable(this);
  }

  @action
  show(message: string): void {
    this.message = message;
    this.isShow = true;
  }

  @action
  hide(): void {
    this.isShow = false;
  }
}

const LoadingIndicatorContext = createContext<LoadingIndicatorState>(new LoadingIndicatorState());

export function useGlobalLoadingIndicator(): LoadingIndicatorState {
  return useContext(LoadingIndicatorContext);
}
