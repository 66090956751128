import React from 'react';

import FeedbackForm from './FeedbackForm';

import { Modal, RequestSentIcon } from '@cadexchanger/react-component-library';

interface FunctionalityNotAvailableModalProps {
  functionality: string;
  isShown: boolean;
  onHide: () => void,
}

export default function FunctionalityNotAvailableModal({
  functionality,
  isShown,
  onHide,
}: FunctionalityNotAvailableModalProps): JSX.Element {

  const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false);

  const onShow = () => {
    setFormSubmitted(false);
  };

  return (
    <Modal
      isShown={isShown}
      onShow={onShow}
      onHide={onHide}
      title={formSubmitted ? '' : `${functionality} is not available in current version`}
    >
      {formSubmitted ? (
        <div className="d-flex flex-column align-items-center">
          <RequestSentIcon className="mb-5"/>
          <h2 className="text-center">Your request has been sent</h2>
          <p className="text-center">We will notify you when this feature will be available.</p>
          <button onClick={onHide} className="btn btn-primary mt-6">Got it!</button>
        </div>
      ) : (
        <>
          <div className="mb-6">Submit your information and subscribe to updates</div>
          <FeedbackForm category={functionality} onSubmit={() => setFormSubmitted(true)} />
        </>
      )}
    </Modal>
  );
}
